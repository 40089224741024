// tailwind.config.js
module.exports = {
  light: '#E6E6E6',
  'extra-light': '#F4F8FF',
  primary: {
    100: '#E6E7EB',
    300: '#667091',
    500: '#071036',
    700: '#010309',
  },
  accent: {
    100: '#FFEDD9',
    300: '#FFD2A0',
    500: '#FFA541',
    700: '#F58100',
  },
  acid: {
    100: '#FBFFE1',
    300: '#F4FEB5',
    500: '#EAFD6B',
    700: '#B4CE03',
  },
  violet: {
    100: '#DDDBF9',
    300: '#ABA4F0',
    500: '#574AE2',
    700: '#1F1584',
  },
  'soft-blue': {
    100: '#E9F2FF',
    300: '#B6D0FE',
    500: '#0073e6',
    700: '#054fb9',
  },
  green: {
    100: '#DEF2DA',
    300: '#ABDEA3',
    500: '#58BD46',
    700: '#35752A',
  },
  red: {
    100: '#FFE3DF',
    300: '#FFB8AE',
    500: '#FF725E',
    700: '#FA1F00',
    900: '#C71700'
  },
  status: {
    'inactive': '#83879A',
    'on-track': '#35752A',
    'regular': '#58BD46',
    'not-regular': '#F58100',
  },
  phishing: {
    unrated: '#ccd1e9',
  },
  services: {
    //Awareness
    1: '#0073e6',
    //Phishing
    2: '#f57600',
    //Channel
    3: '#b51963',
    //Remediation
    4: '#faaf90',
  },
  releaseStatus: {
    'created': '#2c2e34',
    'queued': '#6a6a6a',
    'started': '#d3f1cf',
    'completed': '#58BD46',
    'failed': '#FA1F00',
  },
  warningYellowRGB: '#FFCC00'
}
